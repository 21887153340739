import { Routes } from '@angular/router';

export const rootRouterConfig: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	},
	{
		path: '',
		loadChildren: './views/home/home.module#HomeModule'
	},
	{
		path: '**',
		redirectTo: ''
	}
];
