/**
 * Created by Ooj on 06/10/18.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LeadsService {

  cpl100 = 3.00;
  cpl500 = 2.75;
  cpl1000 = 2.00;

  constructor() { }

  public getCpl(_qty) {
    var avgCpl = 0;
    var totalCost = 0;

    if(_qty < 101) {
      avgCpl = this.cpl100;
      totalCost = _qty * this.cpl100;
    } else {
      if(_qty < 501) {
        totalCost = (this.cpl100 * 100) + ((_qty - 100) * this.cpl500);
        avgCpl = totalCost / _qty;
      } else {
        totalCost = (this.cpl100 * 100) + (this.cpl500 * 400) + ((_qty - 500) * this.cpl1000);
        avgCpl = totalCost / _qty;
      }
    }

    return {
      totalCost: totalCost,
      avgCpl: avgCpl
    }
  }

  public getCplBands() {
    return [
      { key: 'cpl100', val: this.cpl100 },
      { key: 'cpl500', val: this.cpl500 },
      { key: 'cpl1000', val: this.cpl1000 }
    ]
  }

  public getMaxCpl() {
    return this.cpl100;
  }

}
